<template>
	<div style="padding-right:20px">
		<el-row style="display: flex;" :gutter="20">
			<el-col>
				<el-upload class="upload-demo" ref="upload" action :show-file-list="false" :auto-upload="false"
					accept=".xlsx, .xls" :on-change="handleChange">
					<el-button slot="trigger" size="small" type="primary">{{this.$t('devtable.dr')}} XLS</el-button>
					<!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
				</el-upload>
			</el-col>

			<el-col>
				<el-button size="small" type="primary" @click="Excelexport">{{this.$t('devtable.dc')}} XLS</el-button>
			</el-col>

			<!-- <el-button type="primary" >Excel导入</el-button> -->

			<el-col ><a
					:href="languageState?'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-8bca8700-7fe8-4952-97bd-50ce8200db0d/8790a1dc-be69-4cf6-af74-45c95862f17a.xls':'https://web.fondcard.net/enterprise/modelEnglish.xlsx'" download="模板">
					<el-button size="small" type="primary" @click="ExcelexportTemplate">
						{{this.$t('devtable.dc')}}{{this.$t('devtable.drmb')}}{{language}}</el-button>
				</a></el-col>
		</el-row>
	</div>
</template>
<script>
	// import Blob from '@/utils/Blob.js'
	// import Export2Excel from '@/utils/Export2Excel.js'
	export default {
		props: {
			tabfields: Object,
			tableData: Array,
			name: String
		},
		data() {
			return {
				language: window.localStorage.getItem('language'),
				languageState: false
			}
		},
		mounted() {
			if (this.language == 'cn') {
				this.languageState = true
			}else {
				this.languageState = false
			}
		},
		methods: {
			Excelexport() {
				this.$emit('Excelexport')
			},
			ExcelexportTemplate() {
				this.$emit('ExcelexportTemplate')
			},
			submitUpload() {
				this.$emit('submitUpload')
			},
			handleChange(ev) {
				this.$emit('handleChange', ev)
			}
		}
	}
</script>
<style lang="less" scoped>
	.s {}
</style>
