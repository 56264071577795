<template>
  <div>
    <el-dialog
      :title="titles"
      :visible.sync="centerDialogVisible"
      width="850px"
      append-to-body
      center
    >
      <el-row :gutter="50">
        <el-col :span="16">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="93px"
            class="demo-ruleForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  size="small"
                  :label="$t('devtable.xm')"
                  prop="name"
                >
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  size="small"
                  :label="$t('tablename.sjqh')"
                   v-if="language=='cn'"
                >
                  <el-input v-model="address"></el-input>
                </el-form-item>

                <el-form-item
                  size="small"
                  :label="$t('devtable.sjhm')"
                  prop="mobile"
                >
                  <el-input v-model="ruleForm.mobile"></el-input>
                </el-form-item>


              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('devtable.xb')" size="small">
                  <el-select v-model="ruleForm.gender" placeholder="">
                    <el-option
                      :label="$t('devtable.male')"
                      value="0"
                    ></el-option>
                    <el-option
                      :label="$t('devtable.female')"
                      value="1"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('devtable.zjlx')" size="small" :label-width="language=='en'?'120px':''">
                  <el-select v-model="ruleForm.credential_type" placeholder="">
                    <el-option
                      :label="$t('devtable.sfz')"
                      value="0"
                    ></el-option>
                    <el-option :label="$t('devtable.hz')" value="1"></el-option>
                    <el-option
                      :label="$t('devtable.jzz')"
                      value="2"
                    ></el-option>
                    <el-option
                      :label="$t('devtable.jsz')"
                      value="3"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('devtable.jg')" size="small">
                  <el-input v-model="ruleForm.origin"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  size="small"
                  :label="$t('devtable.zjhm')"
                  prop="credential"
                >
                  <el-input v-model="ruleForm.credential"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item size="small" :label="$t('devtable.hj')" :label-width="language=='en'?'80px':''">
                  <el-input v-model="ruleForm.birthplace"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" :label="$t('devtable.csrq')">
                  <el-date-picker
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :placeholder="$t('devtable.xzrq')"
                    v-model="ruleForm.birthday"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item size="small" :label="$t('devtable.rylx')" :label-width="language=='en'?'110px':''">
                  <el-select v-model="ruleForm.type" placeholder="">
                    <el-option :label="$t('devtable.yg')" value="0"></el-option>
                    <el-option :label="$t('devtable.fk')" value="1"></el-option>
                  </el-select>
                  <!-- <el-input v-model="ruleForm.type"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('devtable.mz')" size="small">
                  <el-input v-model="ruleForm.nation"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item
              size="small"
              :label="$t('devtable.qybm')"
              prop="dept_id"
			  :label-width="language=='en'?'100px':''"
            >
              <!-- <Seletree ref="seletree" @selectDepart="selectDepart" :treeList="treeList"></Seletree> -->
              <Select
                :valuet="valuet"
                ref="select"
                @handleCheckChange="handleCheckChange"
              ></Select>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item
                  size="small"
                  :label="$t('devtable.gh')"
                  prop="number"
				  label-width="100px"
                >
                  <el-input v-model="ruleForm.number"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" :label="$t('devtable.kh')" :label-width="language=='en'?'110px':''">
                  <el-input v-model="ruleForm.card_no"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="10">
              <el-col :span="11">
                <el-form-item size="small" :label="$t('devtable.kmmm')" :label-width="language=='en'?'135px':''">
                  <el-input v-model="ruleForm.door_password"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="2">
                                <el-checkbox-group v-model="ruleForm.type1" style="line-height: 32px;">
                                    <el-checkbox label="启用" name="type1"></el-checkbox>
                                </el-checkbox-group>
                            </el-col> -->
              <el-col :span="11">
                <el-form-item size="small" :label="$t('devtable.bz')">
                  <el-input v-model="ruleForm.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('devtable.yxqks')" size="small">
                  <el-date-picker
                    style="width: 100%"
                    v-model="start_time"
                    type="date"
                    :placeholder="$t('devtable.xzrq')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('devtable.yxqjs')" size="small">
                  <el-date-picker
                    style="width: 100%"
                    v-model="end_time"
                    type="date"
                    :placeholder="$t('devtable.xzrq')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-show="this.truef">
              <el-col :span="12" :offset="4" >
                <el-button size="small" style="margin-left:8px" @click="handResetpas" type="primary">{{$t('devtable.czxcxdlmm')}}</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="8">
          <el-image
            v-show="src"
            :src="src"
            style="width: 203px; height: 250px"
          ></el-image>
          <div v-show="!src">
            <video
              id="videoCamera"
              style="background: #c0ccda"
              :width="videoWidth"
              :height="videoHeight"
              autoplay
            ></video>
            <canvas
              style="display: none"
              id="canvasCamera"
              :width="videoWidth"
              :height="videoHeight"
            ></canvas>
          </div>
          <el-col style="margin-top: 10px">
            <el-row>
              <el-button
                :span="12"
                type="primary"
                size="small"
                @click="photograph"
                >2、{{ $t("devtable.pzz") }}</el-button
              >
              <el-button
                :span="12"
                type="primary"
                class="uplouds"
                @click="choiceImg"
                size="small"
                >{{ $t("devtable.cwjxz") }}
                <input
                  id="imagePic"
                  name="imagePic"
                  @change="getFile"
                  ref="filElem"
                  class="butuploud"
                  type="file"
                  accept="image/jpg,image/jpeg"
                />
              </el-button>
            </el-row>
          </el-col>
          <div>
            <el-button
              style="margin-top: 10px"
              type="primary"
              size="mini"
              @click="getCompetence()"
              >1、{{ $t("devtable.dksxt") }}</el-button
            >
            <el-button
              style="margin-top: 10px"
              type="primary"
              size="mini"
              @click="stopNavigator()"
              >3、{{ $t("devtable.gbsxt") }}</el-button
            >
          </div>
          <div class="phonefp">
            <p>{{ this.$t("devtable.zpcjyq") }}</p>
            <p>1.{{ this.$t("devtable.zmrlmgbsz") }}</p>
            <p>2.{{ this.$t("devtable.rlhmzb") }}</p>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="handPreservation">{{
          $t("tablename.qd")
        }}</el-button>
      </span>
    </el-dialog>
    <Vuecropperr ref="vuecrropperr" @finish="finish"></Vuecropperr>
  </div>
</template>
<script>
import methodlist from "@/utils/methods";
import Vuecropperr from "@/views/visitormanagement/components/vuecropperr";
import Select from "@/components/selectree/selectchoice";
import jq from "jquery";
import { formatDate } from "@/utils/date";
var Base64 = require("js-base64").Base64;
export default {
  components: {
    Select,
    Vuecropperr,
  },
  props: {
    titles: String,
    editruleForm: Object,
    truef: Boolean,
  },
  data() {
    return {
      language:window.localStorage.getItem('language'),
      address:'+86',
      centerDialogVisible: false,
      videoWidth: 200,
      videoHeight: 250,
      id: "", //修改的id
      valuet: null,
      value: "",
      start_time: "",
      end_time: "",
      src: "",
      centerDialogVisible: false,
      ruleForm: {
        method: "co.person.add",
        name: "",
        mobile: "",
        gender: "0",
        credential_type: "0",
        origin: "",
        credential: "",
        birthplace: "",
        birthday: "",
        type: "0",
        nation: "",
        dept_id: "",
        number: "",
        card_no: "",
        door_password: "",
        remark: "",
        start_time: "",
        end_time: "",
        agent_id: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("devtable.qsrmz"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("devtable.qsrsjhm"),
            trigger: "blur",
          },
        ],
        dept_id: [
          {
            type: "array",
            required: true,
            message: this.$t("devtable.qxxzbm"),
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: this.$t("devtable.qsrgh"),
            trigger: "blur",
          },
        ],
        credential: [
          {
            required: true,
            message: this.$t("devtable.qsrzjh"),
            trigger: "blur",
          },
        ],
      },
      // 企业部门树
      treeList: [],
      // 修改时暂存的
      card_no: "",
      door_password: "",
      //是否修改开门密码，0-否，1-是
      is_update_door_password: 0,
      //	是否修改人脸，0-否，1-是
      is_update_face: 0,
      //是否修改卡号，0-否，1-是
      is_update_card_no: 0,
      //是否修改权限时间，0-否，1-是
      is_update_key_time: 0,

      faceimg: {
        up_img_name_src: "", //原始名字
        up_img_name_dst: "", //修改名字
        person_id: "", //员工id
        up_img_flag: 0, //上传图片标志
      },
      UserInfo: "",

      updatename:"",
      flags:false
    };
  },
  created() {

    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    this.ruleForm.agent_id = this.UserInfo.agent_id;
    this.faceimg.up_img_flag = 0;
    this.getDeptDataList();
    (this.start_time = this.getNowTime(0)),
      (this.end_time = this.getNowTime(5));
    this.IsChangs();
  },
  methods: {
    // 重置密码
    handResetpas(){
      let data={
        method:'co.user.password.reset',
        id:this.id
      }
      this.$serve(data).then(res=>{
        if(res.data.code==0){
          this.$message({
            message: this.$t("devtable.zzcg"),
            type: "success",
          });
        }else{
          this.$message({
            message: this.$t("devtable.zzsb"),
            type: "error",
          });
        }
      })
    },
    //调用权限（打开摄像头功能）
    getCompetence() {
      var _this = this;
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 拍照
    photograph() {
      var _this = this;
      // canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL("image/jpeg");
      _this.finish(image);
      _this.src = image; //赋值并预览图片
    },
    // 关闭摄像头

    stopNavigator() {
      this.thisVideo.srcObject.getTracks()[0].stop();
    },
    getPersonnel(id) {
      //是否修改开门密码，0-否，1-是
      (this.is_update_door_password = 0),
        //	是否修改人脸，0-否，1-是
        (this.is_update_face = 0),
        //是否修改卡号，0-否，1-是
        (this.is_update_card_no = 0),
        //是否修改权限时间，0-否，1-是
        (this.is_update_key_time = 0),
        (this.id = id);
      let data = {
        method: "co.person.get",
        id: id,
      };

      this.$serve(data).then((res) => {
        console.log(`909777`,res.data.data.mobile.substr(3,res.data.data.mobile.length));
        if (res.data.code == 0) {
          let data = res.data.data;
          this.card_no = data.card_no;
          this.ruleForm.name = data.name;
          this.updatename = data.name
          if(data.mobile.indexOf("-")!='-1'){
              this.address = data.mobile.substr(0,data.mobile.indexOf("-"))
              this.ruleForm.mobile = data.mobile.substr(data.mobile.indexOf("-")+1,res.data.data.mobile.length);
          }else{
            this.ruleForm.mobile = data.mobile;
            this.address = '+86'
          }

          
          
          this.ruleForm.card_no = data.card_no;
          this.ruleForm.dept_id = data.dept_id;
          this.end_time = data.end_time
            ? formatDate(new Date(data.end_time * 1000), "yyyy-MM-dd")
            : ""; //formatDate(new Date(eidtruleForm.end_time*1000), 'yyyy-MM-dd')
          this.start_time = data.start_time
            ? formatDate(new Date(data.start_time * 1000), "yyyy-MM-dd")
            : "";

          this.ruleForm.start_time = Math.round(
            new Date(this.start_time) / 1000
          );
          this.ruleForm.end_time = Math.round(new Date(this.end_time) / 1000);

          // this.ruleForm.mobile = data.mobile;
          this.src =
            methodlist.resident_pic_base +
            data.face_url +
            "?tempid=" +
            Math.random();
          this.ruleForm.gender = data.gender.toString();
          this.ruleForm.origin = data.origin;
          this.ruleForm.credential = data.credential;
          this.ruleForm.birthplace = data.birthplace;
          this.ruleForm.number = data.number;
          this.ruleForm.birthday = data.birthday;
          this.ruleForm.nation = data.nation;
          this.ruleForm.door_password = data.door_password;
          this.door_password = this.ruleForm.door_password;
          this.ruleForm.remark = data.remark;
          this.ruleForm.credential_type = data.credential_type.toString();
          this.ruleForm.type = data.type.toString();
          this.$refs.select.setInitSelection(1, data.dept_id);
        }
      });
    },
    getNowTime(value) {
      var now = new Date();
      var year = now.getFullYear() + value; //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      var hour = " 00:00:00"; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}${hour}`;
      console.log(defaultDate);
      return defaultDate;
      this.$set(this.info, "stockDate", defaultDate);
    },
    // 修改时判断一些参数是否改变
    IsChangs() {
      if (this.truef) {
        this.ruleForm.gender == 1
          ? (this.ruleForm.gender = "女")
          : (this.ruleForm.gender = "男");
        if (this.ruleForm.credential_type == 0) {
          this.ruleForm.credential_type = "身份证";
        } else if (this.ruleForm.credential_type == 1) {
          this.ruleForm.credential_type = "护照";
        } else if (this.ruleForm.credential_type == 2) {
          this.ruleForm.credential_type = "居住证";
        } else {
          this.ruleForm.credential_type = "驾驶证";
        }
        this.card_no = this.ruleForm.card_no;
        this.door_password = this.ruleForm.door_password;
        this.ruleForm.start_time = Math.round(new Date(this.start_time) / 1000);
        this.ruleForm.end_time = Math.round(new Date(this.end_time) / 1000);
      }
    },
    finish(e) {
      //console.log(e)
      console.log("上传base64图片");
      this.src = e;
      this.uploadFaceImg(e);
    },
    // 图片选择上传
    choiceImg() {
      this.$refs.filElem.dispatchEvent(new MouseEvent("click"));
    },
    getFile(e) {
      var file = e.target.files[0];
      console.log(file);
      var filesize = file.size;
      var filename = file.name;
      // 2,621,440   2M
      if (filesize > 2101440) {
        // 图片大于2MB
        console.log(11);
      }
      var reader = new FileReader();
      var _this = this;
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        // var imgcode = e.target.result;
        // _this.imgSrc = e.target.result
        _this.$refs.vuecrropperr.dialogVisible = true;
        _this.$refs.vuecrropperr.option.img = e.target.result;
        // console.log(e.target.result)
      };
    },
    // 拿到企业部门id
    selectDepart(val) {
      // this.ruleForm.dept_id = val.id
      this.ruleForm.dept_id = val;
      console.log(`890`, this.ruleForm.dept_id);
    },
    handleCheckChange(val) {
      this.ruleForm.dept_id = val;
      console.log(`890`, this.ruleForm.dept_id);
    },
    //获取企业架构
    getDeptDataList() {
      const params = {
        method: methodlist.ee_dept_list,
        agent_id: this.UserInfo.agent_id,
        manager_id: this.UserInfo.manager_id,
      };
      this.$serve(params).then((res) => {
        var result = [];
        if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
        this.treeList = result;
      });
    },
    JudePhone(){
        let data={
            method:'co.user.check',
            mobile:this.ruleForm.mobile,
			agent_id:this.UserInfo.agent_id
        }
        this.$serve(data).then(res=>{
            if(res.data.code==0){
                this.flags = true
            }else{
              this.$message({
                message: "手机号存在相同",//this.$t("devtable.xgsb"),
                type: "error",
              });
            }
        })
    },
    // 保存新增
    handPreservation() {
      console.log(`909`, this.ruleForm.start_time);
      // debugger
      if (this.truef) {
        // 编辑
        if (this.card_no != this.ruleForm.card_no) {
          this.is_update_card_no = 1;
        }
        if (this.door_password != this.ruleForm.door_password) {
          this.is_update_door_password = 1;
        }
        if (
          this.ruleForm.start_time !=
            Math.round(new Date(this.start_time) / 1000) ||
          this.ruleForm.end_time != Math.round(new Date(this.end_time) / 1000)
        ) {
          this.is_update_key_time = 1;
        }
        this.ruleForm.id = this.id;
        if(this.updatename != this.ruleForm.name){
          this.ruleForm.is_update_name="1"
        }else{
          this.ruleForm.is_update_name="0"
        }
        
        this.ruleForm.is_update_card_no = this.is_update_card_no;
        this.ruleForm.is_update_door_password = this.is_update_door_password;
        this.ruleForm.is_update_key_time = this.is_update_key_time;
        this.ruleForm.is_update_face = this.is_update_face;

        if(this.address!='+86'){
          this.ruleForm.mobile =this.ruleForm.mobile? this.address+'-'+ this.ruleForm.mobile:''
        }
        

        this.ruleForm.method = "co.person.edit";
        this.ruleForm.start_time = Math.round(new Date(this.start_time) / 1000);
        this.ruleForm.end_time = Math.round(new Date(this.end_time) / 1000);
        if (!this.ruleForm.dept_id) {
          this.$message({
            message: this.$t("devtable.qxxzbm"),
            type: "error",
          });
          return false;
        }
        console.log(`9999`,this.ruleForm.number)
        this.$serve(this.ruleForm).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: this.$t("devtable.xgcg"),
              type: "success",
            });
            if (this.faceimg.up_img_flag == 1) this.deletePersonOldFaceImg();

            this.$parent.getTablelist();
          } else {
            this.$message({
              message: this.$t("devtable.xgsb"),
              type: "error",
            });
          }
        });
        this.centerDialogVisible = false;
        this.$parent.getTablelist();
      } else {
        if (!this.ruleForm.dept_id) {
          this.$message({
            message: this.$t("devtable.qxxzbm"),
            type: "error",
          });
          return false;
        }
        if(this.ruleForm.mobile!=''){
          this.JudePhone()
        }else{
          this.flags = true
        }
        if(!this.flags){
          return false
        }else{
          // 新增
					this.ruleForm.method = "co.person.add";
          this.ruleForm.start_time = Math.round(new Date(this.start_time) / 1000);
          this.ruleForm.end_time = Math.round(new Date(this.end_time) / 1000);
          this.ruleForm.agent_id = this.UserInfo.agent_id;
          if(this.address!='+86'){
            this.ruleForm.mobile =this.ruleForm.mobile? this.address+'-'+ this.ruleForm.mobile:''
          }
          
          this.$serve(this.ruleForm).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.$message({
                message: this.$t("devtable.tjcg"),
                type: "success",
              });

              this.ruleForm.dept_id = "";
              this.$refs.select.mineStatus = "";
              this.$refs.select.initNameList = [];
              this.faceimg.person_id = res.data.data.id;
              if (this.faceimg.up_img_flag == 1) this.deletePersonOldFaceImg();
              this.centerDialogVisible = false;
              this.$parent.getTablelist();
            } else if (res.data.code == -14) {
              this.ruleForm.mobile = ""
              this.$message({
                message: this.$t("devtable.khycz"),
                type: "error",
              });
              this.$refs.select.mineStatus = "";
              return false;
            } else if (res.data.code == -19) {
              this.ruleForm.mobile = ""
              this.$message({
                message: this.$t("devtable.lgsjdbnxt"),
                type: "error",
              });
              this.$refs.select.mineStatus = "";
              return false;
            } else if (res.data.code == -20) {
              this.ruleForm.mobile = ""
              this.$message({
                message: this.$t("devtable.ghycz"),
                type: "error",
              });
              this.$refs.select.mineStatus = "";
              return false;
            } else if (res.data.code == -4) {
              this.ruleForm.mobile = ""
              this.$message({
                message: this.$t("devtable.sjycz"),
                type: "error",
              });
              this.$refs.select.mineStatus = "";
              return false;
            }
          });
        }

        
      }

      this.$refs.select.mineStatus = [];
    },
    getFirstObj(obj) {
      for (var key in obj) {
        return obj[key];
      }
    },

    /*人脸图片处理部分*/
    //上传人脸图片
    uploadFaceImg(imgData) {
      console.log(`909`,imgData)

      let mqttInfo =
        this.UserInfo.mqtt_serial + ":" + this.UserInfo.mqtt_password;
      //mqttInfo = '0AwAA:81905683';//测试用
      let curTimestamp = new Date().getTime();
      this.faceimg.up_img_name_src =
        methodlist.type_face + curTimestamp + ".jpg";
      let up_url_Path =
        methodlist.upload_img_base + this.faceimg.up_img_name_src;
      var data =
        "temporary=true&imgBase64=" +
        imgData.substring("data:image/jpg;base64,".length);
      var _this = this;
      jq.ajax({
        headers: {
          Authorization: "Basic " + Base64.encode(mqttInfo),
          "Content-MD5": this.$md5(data),
        },
        url: up_url_Path,
        type: "POST",
        contentType: "application/x-www-form-urlencoded",
        data: data,
        success: function (res) {
          console.log(res);
          // setTimeout(() => {
          //     _this.renamePersonImgName();
          // }, 2000);
          _this.faceimg.up_img_flag = 1;
          _this.is_update_face = 1;
        },
        error: function (res) {
          console.log(res);
        },
      });
    },
    //修改人脸图片的名称
    renamePersonImgName() {
      let id8HexUp = this.imgHexNameFromPersonId();
      this.faceimg.up_img_name_dst = methodlist.type_face + id8HexUp + ".jpg";

      if (
        this.faceimg.up_img_name_src.length == 0 ||
        this.faceimg.up_img_name_dst.length == 0
      ) {
        console.log("图片文件路径错误！");
        return false;
      }

      let mqttInfo =
        this.UserInfo.mqtt_serial + ":" + this.UserInfo.mqtt_password;

      //mqttInfo = '0AwAA:81905683';//测试用

      var data = "temporary=true&rename=" + this.faceimg.up_img_name_dst;
      var _this = this;
      jq.ajax({
        headers: {
          Authorization: "Basic " + Base64.encode(mqttInfo),
          "Content-MD5": this.$md5(data),
        },
        url: methodlist.upload_img_base + this.faceimg.up_img_name_src,
        type: "POST",
        contentType: "application/x-www-form-urlencoded",
        data: data,
        success: function (res) {
          console.log("改名成功:", res);
          _this.freshServerImgName(res);
        },
        error: function (res) {
          console.log("改名失败:", res);
        },
      });
    },

    //删除没用的人脸图片
    deletePersonOldFaceImg() {
      let mqttInfo =
        this.UserInfo.mqtt_serial + ":" + this.UserInfo.mqtt_password;
      //mqttInfo = '0AwAA:81905683';//测试用
      var id8HexUp = this.imgHexNameFromPersonId();
      var delImgPath =
        methodlist.upload_img_base + methodlist.type_face + id8HexUp + ".jpg";
      var data = "";
      var _this = this;
      jq.ajax({
        headers: {
          Authorization: "Basic " + Base64.encode(mqttInfo),
          "Content-MD5": data,
        },
        url: delImgPath,
        type: "DELETE",
        contentType: "false",
        data: data,
        success: function (res) {
          console.log("删除人脸图片OK", JSON.stringify(res));
          _this.deletePersonOldFaceTemp();
        },
        error: function (res) {
          console.log("删除人脸图片Err", JSON.stringify(res));
          _this.deletePersonOldFaceTemp();
        },
      });
    },
    //删除没用的人脸模板
    deletePersonOldFaceTemp() {
      let mqttInfo =
        this.UserInfo.mqtt_serial + ":" + this.UserInfo.mqtt_password;
      //mqttInfo = '0AwAA:81905683';//测试用

      var id8HexUp = this.imgHexNameFromPersonId();
      var delFaceTempUrl =
        methodlist.upload_img_base + methodlist.type_temp + id8HexUp + ".bin";
      var data = "";
      var _this = this;
	  console.log(delFaceTempUrl);
      jq.ajax({
        headers: {
          Authorization: "Basic " + Base64.encode(mqttInfo),
          "Content-MD5": data,
        },
        url: delFaceTempUrl,
        type: "DELETE",
        contentType: "false",
        data: data,
        success: function (res) {
          console.log("删除人脸模板OK", JSON.stringify(res));
          setTimeout(() => {
            _this.renamePersonImgName();
          }, 2 * 1000);
        },
        error: function (res) {
          console.log("删除人脸模板Err", JSON.stringify(res));
          setTimeout(() => {
            _this.renamePersonImgName();
          }, 2 * 1000);
        },
      });
    },
    imgHexNameFromPersonId() {
      //this.faceimg.person_id = 1000;
      let num = parseInt(this.faceimg.person_id).toString(16);
      let id8Hex = ("00000000" + num).slice(-8);
      let id8HexUpName = id8Hex.toUpperCase();
      return id8HexUpName;
    },
    //更新服务器
    freshServerImgName(img_url) {
      console.log(`9094`,img_url)
      const params = {
        method: methodlist.ee_update_person_pic,
        id: this.faceimg.person_id,
        pic_url: img_url,
      };
      this.$serve(params).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          //添加访客成功完成
          this.$parent.getTablelist();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.phonefp {
  margin-top: 65px;
  p {
    margin-top: 10px;
    color: red;
  }
}
.el-dialog /deep/ .el-dialog--center .el-dialog__body {
  padding: 7px 25px 30px;
}
.btn {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c0ccda;
  color: #1f2d3d;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 20px 10px 0px 0px;
  padding: 9px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: #50bfff;
  border-color: #50bfff;
  transition: all 0.2s ease;
  text-decoration: none;
  user-select: none;
}
.uplouds {
  position: relative;
  .butuploud {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }
}
</style>