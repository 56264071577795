<template>
	<div class="datasoures">
		<div style="display: flex; justify-content: space-between">
			<div style="display: flex">
				<Headbuttom></Headbuttom>
				<Tabheader :flags="true" @handRegister="handRegister" @handFromshow="handFromshow"
					:titlename="titlename">
				</Tabheader>
			</div>
			<Excelbutom @Excelexport="Excelexport" @handleChange="handleChange" @submitUpload="submitUpload"
				:tableData="tableData" :name="name" @ExcelexportTemplate='ExcelexportTemplate'></Excelbutom>
		</div>
		<div class="fromshea" v-show="iShow">
			<el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item :label="$t('devtable.bm')">
					<el-select v-model="mineStatus" placeholder="" multiple collapse-tags>
						<el-option :value="mineStatus" style="height: auto">
							<el-tree :data="treeList" default-expand-all show-checkbox check-strictly node-key="id"
								ref="tree" highlight-current :props="defaultProps" @check-change="handleCheckChange">
							</el-tree>
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('devtable.xm')">
					<el-input v-model="formInline.name" :placeholder="$t('devtable.xm')"></el-input>
				</el-form-item>

				<el-form-item :label="$t('devtable.gh')">
					<el-input v-model="formInline.number" :placeholder="$t('devtable.gh')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('devtable.sjhm')">
					<el-input v-model="formInline.mobile" :placeholder="$t('devtable.sjhm')"></el-input>
				</el-form-item>

				<el-form-item :label="$t('devtable.kh')">
					<el-input v-model="formInline.card_no" :placeholder="$t('devtable.kh')"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">{{
            $t("tablename.qd")
          }}</el-button>
				</el-form-item>
			</el-form>
		</div>
		<Tables @handlerow="handlerow" @sortChange="sortChange" @handles="handles" @handlpages="handlpages"
			@handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags"
			:configs="configs" :operation="operation"></Tables>
		<Perinfdiolg ref="perinfdiolg" :titles="titles" :truef="truef"></Perinfdiolg>
		<Diolg ref="diolg" :dialog="dialog" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
			<div style="display: flex; justify-content: center; max-height: 250px">
				<el-image :src="preview_imgurl" fit="cover"></el-image>
			</div>
		</Diolg>
		<Userdialog @userhandleClose="userhandleClose" :userdialog="userdialog"
			@userdialogVisibletrue="userdialogVisibletrue">
			<el-form :model="delform">
				<el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
					<el-input v-model="delform.user_name_input" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
					<el-input v-model="delform.user_pass_input" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
		</Userdialog>
		<!-- 错误弹窗 -->
		<div class="errPop" v-show="errPopState">
			<div class="close" @click="closePop">X</div>
			<div class="popTitle">{{$t('devtable.drsb')}}</div>
			<div class="popNav">
				<div class="navIndex">{{$t('devtable.hss')}}</div>
				<div class="navIndex">{{$t('devtable.xm')}}</div>
				<div class="navIndex">{{$t('devtable.yy')}}</div>
			</div>
			<div>
				<div class="scroll">
					<div v-for="(item,index) in errDataList" :key="index" class="itemBox">
						<div class="navIndex">{{index+1}}</div>
						<div class="navIndex">{{item.name}}</div>
						<div class="navIndex" v-if="item.error==-1||item.error==-4||item.error==-5">
							{{item.error==-1?'部门为空':(item.error==-4?'用户名或手机号已存在':'添加用户失败')}}</div>
						<div class="navIndex" v-if="item.error==-20">{{$t('devtable.ghycz')}}</div>
						<div class="navIndex" v-if="item.error==-14">{{$t('devtable.khycz')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Tables from "@/components/table";
	import Tabheader from "@/views/workattendance/components/tabheader";
	import Excelbutom from "@/components/excel";
	import Perinfdiolg from "./components/perinfdiolg";
	import {
		formatDate
	} from "../../utils/date";
	import {
		timestampToTime2
	} from "../../utils/index"
	import methodlist from "@/utils/methods";
	import Userdialog from "@/components/dialog/userfrom";
	import xlxs from "xlsx";
	import Diolg from "@/components/dialog/img.vue";
	import {
		readFile,
		character,
		timer,
		hanldArray,
		Qiyebum
	} from "@/utils";

	var loginObj = JSON.parse(window.localStorage.getItem("user"));
	var v = 1;
	var falgttt = false;
	export default {
		components: {
			Tables,
			Tabheader,
			Excelbutom,
			Perinfdiolg,
			Diolg,
			Userdialog,
		},
		data() {
			return {
				language:window.localStorage.getItem('language'),
				dataList: [],
				page_indexs: 0,
				errDataList: [], // 导入 错误列表
				errPopState: false, // 导入错误 弹窗 状态
				totals: null,
				order_by: 0,
				preview_imgurl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",

				mineStatus: "",
				treedata: [],
				defaultProps: {
					children: "children",
					label: "name",
					value: "id",
					disabled: "is_valid",
				},
				treeList: [],
				truef: false,
				titles: this.$t("devtable.tjryzl"),
				name: this.$t("devtable.ryzl"),
				iShow: false,
				formInline: {
					user: "",
					region: "",
				},
				input: "",
				titlename: {
					name: this.$t("navLeft.add"),
					placeholdername: this.$t("devtable.qdjcx"),
				},

				tableData: [],
				flags: {
					show: true, // 多选是否显示
					ishow: false, // 序号是否显示
				},
				// 分页数据
				configs: {
					page_index: 1,
					pagesize: 10,
					total: 0,
					loading: false, // 加载状态
				},
				props: {
					value: "id",
					label: "name",
				},

				dept_id: "",
				userdialog: {
					dialogVisible: false,
					title: this.$t("devtable.tsxx"),
				},
				delform: {
					user_name_input: "",
					user_pass_input: "",
				},
				formLabelWidth: "80px",
				tableDataex: [],
				UserInfo: "",
				intertimer: null,
				loading: null
			};
		},
		computed: {
			dialog: function() {
				return {
					dialogVisible: false,
					title: this.$t("devtable.ylrxzp"),
				};
			},
			tableLabel: function() {
				return [{
						prop: "id",
						label: this.$t("devtable.zcid"),
						align: "center",
						width:130
					},
					{
						prop: "name",
						label: this.$t("devtable.xm"),
						align: "center",
					},

					{
						prop: "number",
						label: this.$t("devtable.gh"),
						align: "center",
						sortable: true,
						width:130
					},
					{
						prop: "dept_name",
						label: this.$t("devtable.bm"),
						align: "center",
						width:100
					},
					{
						prop: "gender",
						label: this.$t("devtable.xb"),
						align: "center",
					},
					{
						prop: "mobile",
						label: this.$t("devtable.sjhm"),
						align: "center",
					},
					{
						prop: "credential",
						label: this.$t("devtable.rq"),
						// label: '日期',
						align: "center",
					},
					{
						prop: "card_no",
						label: this.$t("devtable.kh"),
						align: "center",
						width:110
					},
					{
						prop: "face_url_hint",
						label: this.$t("devtable.rxzp"),
						align: "center",
						width:110
					},
				];
			},
			operation: function() {
				return {
					width: "280px",
					data: [{
							name: this.$t("devtable.xg"),
							type: "primary",
						},
						{
							name: this.$t("tablename.jsj"),
							type: "warning",
						},
						{
							name: this.$t("tablename.sc"),
							type: "danger",
						},
					],
				};
			},
			most: function() {
				return {
					yl: this.$t("devtable.yl"),
					male: this.$t("devtable.male"),
					female: this.$t("devtable.female"),
				};
			},
		},

		mounted() {
			this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
			this.getDeptDataList();
			this.getTablelist();
			console.log(this.UserInfo);
		},

		methods: {
			// 导出导入模板
			ExcelexportTemplate(){
				// window.location.href='https://web.fondcard.net/static/model.xlsx'
			},
			// 关闭导入失败弹窗
			closePop() {
				this.errPopState = false
			},
			//关闭删除框
			userhandleClose() {
				console.log("userhandleClose");
				this.userdialog.dialogVisible = false;
			},
			//获取企业部门id
			handleCheckChange(data, checked, node) {
				this.mineStatus = [];
				this.dept_id = data.id;
				let res = this.$refs.tree.getCheckedNodes(false, true); //true，1. 是否只是叶子节点 2.选择的时候不包含父节点）
				if (checked) {
					this.$refs.tree.setCheckedNodes([data]);
				}
				let arrLabel = [];
				// console.log(res, 'res')
				res.forEach((item) => {
					if (arrLabel.length === 0) {
						arrLabel.push(item.name);
					} else {
						arrLabel.length === 0;
					}
				});
				this.mineStatus = arrLabel.slice(0, 1);
			},
			//获取企业架构
			getDeptDataList() {
				const params = {
					method: methodlist.ee_dept_list,
					agent_id: this.UserInfo.agent_id,
					manager_id: this.UserInfo.manager_id,
				};
				this.$serve(params).then((res) => {
					// this.configs.total = res.data

					var result = [];
					if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
					this.treeList = Qiyebum(result);
				});
			},
			// 数据修改 / 删除
			handlerow(v, name) {
				if (name == "修改" || name == "Modify") {
					// let data = {
					//   method: "co.person.get",
					//   id: v.id,
					// };
					// this.$serve(data).then((res) => {
					//   if (res.data.code == 0) {
					//     let eidtruleForm = res.data.data;
					//     this.$refs.perinfdiolg.getPersonnel(eidtruleForm.id);
					//   }
					// });
					this.$refs.perinfdiolg.getPersonnel(v.id);
					this.$refs.perinfdiolg.faceimg.person_id = v.id;
					this.titles = this.$t("devtable.xgryzl");
					this.truef = true;
					this.$refs.perinfdiolg.centerDialogVisible = true;
					this.$refs.perinfdiolg.faceimg.up_img_flag = 0;
				}else if(name == "加时间"||name=="Add Time"){
					console.log(v,name)
					let data = {
						method: "co.person.time.add",
						id: v.id,
					}
					this.$serve(data).then((res)=>{
						console.log(res)
						if(res.data.code==0){
							this.$message({
								type:"success",
								message:'增加时间成功'
							})
							this.getTablelist()
						}else if(res.data.code==-3){
							this.$message({
								type:"error",
								message:'人员不存在'
							})
						}else{
							this.$message({
								type:"error",
								message:'添加失败，错误码'+res.data.code
							})
						}
					})
				}else {
					this.delData = v;
					this.userdialog.dialogVisible = true;
				}
				 
			},
			//删除弹窗: 确定删除访客
			userdialogVisibletrue() {
				console.log("userdialogVisibletrue", this.delform.user_name_input);
				console.log(this.delform.user_name_input,this.delform.user_pass_input);
				console.log(loginObj.user_name_save,loginObj.user_pass_save);
				this.userdialog.dialogVisible = false;
				if (this.delform.user_name_input != loginObj.user_name_save) {
					this.$message({
						type: "warning",
						message: this.$t("devtable.yhmsryw"),
					});
					return false;
				}

				if (this.delform.user_pass_input != loginObj.user_pass_save) {
					this.$message({
						type: "warning",
						message: this.$t("devtable.yhmmsryw"),
					});
					return false;
				}
				const params = {
					method: "co.person.del",
					name: this.delform.user_name_input,
					password: this.$md5(this.delform.user_pass_input),
					id: this.delData.id,
				};
				this.$serve(params).then((res) => {
					console.log(res);
					if (res.data.code == 0) {
						this.$message({
							message: this.$t("devtable.sccg"),
							type: "success",
						});
						this.getTablelist();
					} else {
						//删除失败
						if (res.data.code == -3) {
							this.$message({
								message: this.$t("devtable.scsbryzlbcz"),
								type: "warning",
							});
						} else {
							this.$message({
								message: this.$t("devtable.scsb"),
								type: "warning",
							});
						}
					}
				});
			},
			// 排序
			sortChange(v) {
				if (v.order == "descending") {
					this.order_by = 1;
					this.getTablelist();
				}
				if (v.order == "ascending") {
					this.order_by = 0;
					this.getTablelist();
				}
			},
			// 获取表格列表数据
			getTablelist(v, val) {
				let data = {
					method: "co.person.list",
					page_index: this.configs.page_index,
					page_size: this.configs.pagesize,
					name: this.formInline.name ? this.formInline.name : "",
					mobile: this.formInline.mobile ? this.formInline.mobile : "",
					manager_id: this.UserInfo.manager_id,
					card_no: this.formInline.card_no ? this.formInline.card_no : "",
					agent_id: this.UserInfo.agent_id,
					dept_id: this.dept_id ? this.dept_id : "-1",
					order_by: this.order_by,
					number: this.formInline.number ? this.formInline.number : "",
				};
				this.$serve(data).then((res) => {
					let data = res.data.data;
					console.log(`sdasd`, res);
					falgttt = false;
					data.forEach((element) => {
						if (element.gender == 0) {
							element.gender = {
								name: this.$t("devtable.male"),
								state: true,
								type: "primary",
							};
						} else {
							element.gender = {
								name: this.$t("devtable.female"),
								state: true,
								type: "success",
							};
						}

						element.face_url_hint = {
							name: this.$t("devtable.yl"),
							but: true,
							type: "success",
						};
					});

					this.configs.total = res.data.count;
					this.totals = res.data.count
					this.tableData = data;
					console.log(`000`, this.tableData);
				});
			},
			// setTimeoust(){

			//   var clearTimeSet=setInterval( () =>{
			//         this.page_indexs ++
			//         console.log(`888`,this.page_indexs)
			//         this.settimae(this.page_indexs)
			//         if(this.page_indexs>this.totals){
			//           clearInterval(clearTimeSet)
			//         }
			//   }, 500);
			// },

			// 定时器
			settimae(page_indexs) {
				let data = {
					method: "co.person.list",
					page_index: 1,
					page_size: 99999,
					name: "",
					mobile: "",
					manager_id: this.UserInfo.manager_id,
					card_no: "",
					agent_id: this.UserInfo.agent_id,
					dept_id: "-1",
				};
				this.$serve(data).then((res) => {
					let data = res.data.data;
					console.log(`sdasd`, res);
					falgttt = false;
					data.forEach((element) => {
						if (element.gender == 0) {
							element.gender = {
								name: this.$t("devtable.male"),
								state: true,
								type: "primary",
							};
						} else {
							element.gender = {
								name: this.$t("devtable.female"),
								state: true,
								type: "success",
							};
						}

						element.face_url_hint = {
							name: this.$t("devtable.yl"),
							but: true,
							type: "success",
						};
					});

					this.configs.total = res.data.count;
					// this.totals = res.data.count;
					this.tableDataex = data // this.tableDataex.concat(data);
					// console.log(`9999`,this.tableDataex )
				});
			},
			//分页
			handlpages(v) {
				this.configs.page_index = v
				this.getTablelist();
			},
			handleSizeChange(val) {
				this.configs.pagesize = val
				this.configs.page_index = 1
				this.getTablelist();
			},
			handles(val) {
				console.log(`909s`, val);
				this.$refs.diolg.dialogVisible = true;
				//this.src= val.src?val.src:'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
				//val.face_url = '/face/0/000024D4.jpg'
				if (val.face_url.length > 0) {
					this.preview_imgurl =
						methodlist.resident_pic_base +
						val.face_url +
						"?tempid=" +
						Math.random();
					this.src = this.preview_imgurl;
				} else {
					this.preview_imgurl =
						" https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg";
				}
			},
			handleClose() {
				this.$refs.diolg.dialogVisible = false;
			},
			dialogVisibletrue() {
				this.$refs.diolg.dialogVisible = false;
			},
			handRegister() {
				this.truef = false;
				(this.titles = this.$t("devtable.tjryzl")),
				(this.$refs.perinfdiolg.ruleForm.name = "");
				this.$refs.perinfdiolg.ruleForm.mobile = "";
				this.$refs.perinfdiolg.ruleForm.card_no = "";
				this.$refs.perinfdiolg.ruleForm.dept_id = "";
				this.$refs.perinfdiolg.ruleForm.end_time =
				""; //formatDate(new Date(eidtruleForm.end_time*1000), 'yyyy-MM-dd')
				this.$refs.perinfdiolg.ruleForm.start_time = "";
				this.$refs.perinfdiolg.ruleForm.mobile = "";
				this.$refs.perinfdiolg.src = "";
				this.$refs.perinfdiolg.ruleForm.gender = "0";
				this.$refs.perinfdiolg.ruleForm.origin = "";
				this.$refs.perinfdiolg.ruleForm.credential = "";
				this.$refs.perinfdiolg.ruleForm.birthplace = "";
				this.$refs.perinfdiolg.ruleForm.number = "";
				this.$refs.perinfdiolg.ruleForm.birthday = "";
				this.$refs.perinfdiolg.ruleForm.nation = "";
				this.$refs.perinfdiolg.ruleForm.door_password = "";
				this.$refs.perinfdiolg.ruleForm.remark = "";
				this.$refs.perinfdiolg.ruleForm.credential_type = "";
				this.$refs.perinfdiolg.ruleForm.type = "";

				this.$refs.perinfdiolg.centerDialogVisible = true;
				// this.$refs.perinfdiolg.$refs.select.mineStatus=[]
			},
			handFromshow() {
				this.iShow = !this.iShow;
			},
			onSubmit() {
				this.getTablelist();
				// let data = {
				// dept_id:this.dept_id,
				// agent_id:this.UserInfo.agent_id,
				// card_no:this.formInline.card_no,
				// mobile:this.formInline.mobile,
				// name:this.formInline.name,
				// page_size:10,
				// page_index:1,
				// method:'co.person.list',
				// number:this.formInline.number
				// }
				// this.$serve(data).then(res=>{
				// this.tableData = res.data.data
				// })
			},
			Excelexport() {
				this.settimae()

				// console.log(this.tableData)
				// return
				if (this.tableDataex.length > 0) {
					let arr = this.tableDataex.map((item) => {
						console.log(item);
						if(this.language=='cn'){
							return {
								姓名: item.name,
								企业部门: item.dept_name,
								性别: item.gender.name,
								手机号码: item.mobile,
								卡号: item.card_no,
								工号: item.number,
							};
						}else{
							return {
								Name: item.name,
								Department: item.dept_name,
								Gender: item.gender.name,
								Phone: item.mobile,
								CardNumber: item.card_no,
								JobNumber: item.number,
							};
						}
					});
					// 将数据转换为表格
					let sheet = xlxs.utils.json_to_sheet(arr);
					let book = xlxs.utils.book_new();
					xlxs.utils.book_append_sheet(book, sheet, "sheet1");
					xlxs.writeFile(book, this.$t('navLeft.ryzl') + `.xls`);
				}
			},

			// 导出错误列表
			ExcelexportErrList(dataArr) {
				this.settimae()

				// console.log(this.tableData)
				// return
				if (dataArr.length > 0) {
					let arr = dataArr.map((item) => {
						console.log(item);
						if(this.language=='cn'){
							return {
								姓名: item.name,
								企业部门: item.dept_name,
								性别: item.gender.name,
								手机号码: item.mobile,
								卡号: item.card_no,
								工号: item.number,
							};
						}else{
							return {
								Name: item.name,
								Department: item.dept_name,
								Gender: item.gender.name,
								Phone: item.mobile,
								CardNumber: item.card_no,
								JobNumber: item.number,
							};
						}
					});
					// 将数据转换为表格
					let sheet = xlxs.utils.json_to_sheet(arr);
					let book = xlxs.utils.book_new();
					xlxs.utils.book_append_sheet(book, sheet, "sheet1");
					xlxs.writeFile(book, this.$t('导入失败资料') + `.xls`);
				}
			},

			submitUpload() {},
			async handleChange(ev) {
				// 有文件才上传
				let file = ev.raw;
				if (!file) return;
				// 读取File中的数据
				let data = await readFile(file);
				let workbook = xlxs.read(data, {
					type: "binary"
				});
				// console.log(workbook)
				// 表格
				let worksheet = workbook.Sheets[workbook.SheetNames[0]];
				// 将数据转化为json格式
				// console.log(worksheet)
				data = xlxs.utils.sheet_to_json(worksheet);
				console.log('900', data);
				// 将json格式的值循环读取变为最后可以传递给服务器的数据
				let arr = [];
				let errList = []
				let errExportState = false
				data.forEach((item) => {
					let obj = {};
					for (let key in character) {
						if (!character.hasOwnProperty(key)) break;
						// debugger
						let v = character[key],
							text = v.text,
							type = v.type;
						//
						v = item[text] || "";
						// console.log(`55`,v)
						type === "string" ? (v = String(v)) : null;
						if (type === "number") {
							if (v == "男"||v=='male') {
								v = 0;
							} else {
								v = 1;
							}
							// if(text == "是否导入"&&v == "" ){
							//            v = 0;
							// }
						}
						obj[key] = v;
						// console.log(`55`,v)

					}
					// console.log('77',obj);
					arr.push(obj);
					this.dataList.push(obj)
				});
				
				// console.log(`666`, this.dataList);
				// getPageInter(map) {
				this.loading = this.$loading({
					//加载层
					lock: true,
					text: "正在导入中,请勿关闭",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				this.intertimer = setInterval(() => {
					// debugger
					console.log('rrr',arr.length)
					if (arr.length <= 10) {
						// this.$message({
						//     message: "导入成功",
						//     type: "success",
						// });
						errExportState=true
						this.getTablelist();
						clearInterval(this.intertimer); //关闭定时器
						this.loading.close(); //关闭弹窗
					}
					// this.intervalData(map); //每三秒调用一次方法
					// console.log('qian',arr);
					// for(let i = 0 ; i < arr.length ; i++){
					// 	console.log(arr[i].state)
					// 	if(arr[i].state==''){
					// 		arr.splice(i,1)
					// 		i--
					// 	}
					// 	// arr = arr
					// }
					// arr.forEach((item,index)=>{
					// 	if(item.state==0){
					// 		console.log('进forEach')
					// 		arr.splice(index,1)
					// 	}
					// })
					// console.log('hou',arr);
					// let conArr = []
					// if
					 //关闭定时器
					this.loading.close(); //关闭弹窗 
					// for(let i = 0 ; i < arr.length ; i++){
					// 	conArr.push(arr[i])
					// 	if(i == 10){
					// 		break
					// 	}
					// }
					// let fromdata = hanldArray(conArr, this.treeList);
					let fromdata = hanldArray(arr.splice(0, 10), this.treeList);
					
					// console.log('lll',arr.splice(0, 10))
					console.log('lll1',fromdata)
					fromdata.method = "co.person.import";
					fromdata.agent_id = this.UserInfo.agent_id
					delete fromdata.face_url
					console.log(fromdata);
					this.$serve(fromdata).then((res) => {
						if (res.data.data.length==0) {
							this.$message({
								message: "导入成功",
								type: "success",
							});
							this.getTablelist();
						} else {
							// this.errDataList = res.data.data
							this.errPopState = true
							// this.errDataList = []
							res.data.data.forEach(item => {
								errList.push(item)
							})
							this.errDataList = errList
							console.log('e',this.errDataList)
							let exportErr = []
							for (let i = 0; i < this.errDataList.length; i++) {
								for (let j = 0; j < this.dataList.length; j++) {
									if (this.errDataList[i].name == this.dataList[j].name) {
										exportErr.push(this.dataList[j])
									}
								}
							}
							if(errExportState){
								this.ExcelexportErrList(exportErr)
							}
							console.log('errArr', exportErr)
							this.$message({
								message: `导入失败`,
								type: "error",
							});

							// this.$message({
							// 	message: res.data.data,
							// 	type: "error",
							// });
							// clearInterval(this.intertimer); //关闭定时器
							this.loading.close(); //关闭弹窗  
							// clearInterval(this.intertimer)
						}

					});
				}, 300);
			},

			getFirstObj(obj) {
				for (var key in obj) {
					return obj[key];
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.datasoures {
		padding: 20px;
		background-color: #fff;

		.fromshea {
			margin-top: 20px;
			padding: 20px;
		}

		.shear {
			display: flex;

			span {}
		}
	}

	.name {
		flex: 2;
		line-height: 30px;
	}

	.tbut {
		margin-left: 20px;
	}

	.el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		background-color: #fff;
	}

	.errPop {
		position: fixed;
		top: 20px;
		left: 500px;
		background-color: #CCCCCC;
		width: 500px;
		border-radius: 10px;
	}

	.close {
		border: 1px solid #000;
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		margin-left: auto;
		margin-right: 10px;
		margin-top: 10px;
	}

	.popTitle {
		font-size: 24px;
		text-align: center;
		margin-bottom: 20px;
	}

	.popNav {
		display: flex;
		margin-bottom: 10px;
	}

	.navIndex {
		flex: 3;
		text-align: center;
	}

	.scroll {
		height: 220px;
		overflow-y: scroll;
	}

	.itemBox {
		display: flex;
		border-top: 1px solid #000;
		margin-bottom: 10px;
		padding-top: 10px;
	}
</style>
